import React from 'react';

import EuropaPark from 'mocks/webflow/europa-park';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/webflow-europa-park.scss';

const EuropaParkPage = () => {
  return (
    <div
      className="webflow webflow-europa-park"
      dangerouslySetInnerHTML={{ __html: EuropaPark }}
    ></div>
  );
};

export default EuropaParkPage;
